@font-face {
	font-family: 'icomoon';
	src:url('/font/icomoon.eot?x05w1u');
	src:url('/font/icomoon.eot?#iefixx05w1u') format('embedded-opentype'),
		url('/font/icomoon.ttf?x05w1u') format('truetype'),
		url('/font/icomoon.woff?x05w1u') format('woff'),
		url('/font/icomoon.svg?x05w1u#icomoon') format('svg');
	font-weight: normal;
	font-style: normal;
}

[class^="icon-"], [class*=" icon-"] {
	font-family: 'icomoon';
	speak: none;
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	line-height: 1;

	/* Better Font Rendering =========== */
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.icon-star:before {
	content: "\f005";
}
.icon-pencil:before {
	content: "\f040";
}
.icon-facebook-square:before {
	content: "\f082";
}
.icon-twitter:before {
	content: "\f099";
}
.icon-github:before {
	content: "\f09b";
}
.icon-google-plus:before {
	content: "\f0d5";
}
.icon-envelope:before {
	content: "\f0e0";
}
.icon-linkedin:before {
	content: "\f0e1";
}
.icon-instagram:before {
	content: "\f16d";
}
.icon-flickr:before {
	content: "\f16e";
}
.icon-slideshare:before {
	content: "\f1e7";
}
